import { gql } from "@apollo/client";

export const GET_PRICES = gql`
  query prices($where: BasePriceFilterInput) {
    prices(where: $where) {
      clusterId
      id
      markups
      price
      sku
      steps
      storeId
      comment
    }
  }
`;

export const UPDATE_PRICE = gql`
  mutation updatePrice($updatePrice: UpdatePriceInput) {
    updatePrice(updatePrice: $updatePrice) {
      clusterId
      id
      markups
      price
      sku
      steps
      storeId
      cluster {
        id
        name
        warehouses
      }
    }
  }
`;

export const DELETE_PRICE = gql`
  mutation deletePrice($updatePrice: DeletePriceInput) {
    deletePrice(updatePrice: $updatePrice)
  }
`;
